import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ThresholdService} from "../../../../../core/ek-e-commerce/ek-services/threshold.service";
import {ThresholdModel} from "../../../../../core/ek-e-commerce/ek-models/threshold.model";
import {LayoutUtilsService, MessageType} from "../../../../../core/_base/crud";

@Component({
    selector: 'kt-scoring-threshold',
    templateUrl: './scoring-threshold.component.html',
    styleUrls: ['./scoring-threshold.component.scss']
})
export class ScoringThresholdComponent implements OnInit {

    thresholdForm: FormGroup;
    ratingOptions: number[] = [];  // Array to hold threshold options
    Threshold: ThresholdModel;

    constructor(private fb: FormBuilder,
                private thresholdService: ThresholdService,
                private layoutUtilsService: LayoutUtilsService) {
    }

    ngOnInit(): void {

        // Initialize form and options
        this.thresholdForm = this.fb.group({
            minThreshold: ['', Validators.compose([Validators.required])],
            maxThreshold: ['', Validators.compose([Validators.required])]
        });

        //get rating threshold min/max
        this.thresholdService.getAll().subscribe({
            next: (res) => {
                if (res && res[0]) {
                    this.Threshold = res[0];
                    this.thresholdForm.get('minThreshold').setValue(res[0].min);
                    this.thresholdForm.get('maxThreshold').setValue(res[0].max);
                }
            },
            error: () => {
                const message = `Une erreur s'est produite lors du chargement du seuil !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            }
        });

        // Populate the rating options from 1 to 10
        this.ratingOptions = Array.from({length: 10}, (_, i) => i + 1);


    }


    updateThresHold() {

        if (this.Threshold) {

            this.Threshold.min = Number(this.thresholdForm.get('minThreshold').value);
            this.Threshold.max = Number(this.thresholdForm.get('maxThreshold').value);

            this.thresholdService.update(this.Threshold).subscribe({
                next: (res) => {
                    const message = `Le seuil de rating a été mis à jour avec succès `;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }, error: () => {
                    const message = `Une erreur est survenue lors de la mis à jour du seuil. Veuillez réessayer !`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }
            });
        } else {
            this.addNewThresHold();
        }

    }

    addNewThresHold() {

        let threshold: ThresholdModel = {
            id: null,
            max: Number(this.thresholdForm.get('maxThreshold').value),
            min: Number(this.thresholdForm.get('minThreshold').value)
        };

        this.thresholdService.save(threshold).subscribe({
            next: (res) => {
                this.Threshold = res;
                const message = `Le seuil de rating a été sauvegardé avec succès `;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            },
            error: () => {
                const message = `Une erreur est survenue lors de la sauvegarde du seuil. Veuillez réessayer !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            }
        });
    }
}
