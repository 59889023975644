import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {OrdersListDialogComponent} from "../orders-list-dialog/orders-list-dialog.component";
import {MatSort} from "@angular/material/sort";
import {OrdersService} from "../../../../../../core/e-commerce/_services/orders.service";
import {OrderModel2} from "../../../../../../core/e-commerce/_models/order2.model";
import {FormControl, FormGroup} from "@angular/forms";
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {fromEvent, merge, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {ResizeEvent} from "angular-resizable-element";
import {Wilaya, WilayaService} from "../../../../../../core/e-commerce/_services/wilaya.service";
import {RegionModel} from "../../../../../../core/e-commerce/_models/region.model";
import {RegionService} from "../../../../../../core/e-commerce/_services/region.service";
import {OrderExcelMonthlyComponent} from "../order-excel-monthly/order-excel-monthly.component";
import {ParcelService} from "../../../../../../core/e-commerce/_services/parcel.service";
import {LayoutUtilsService, MessageType, QueryParamsModel} from "../../../../../../core/_base/crud";
import {WrongOrdersBillComponent} from "../wrong-orders-bill/wrong-orders-bill.component";
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
import {
    OrdersAllPageRequested,
    OrdersByClientPageRequested,
    OrdersByWilayaRequested,
    updateYalidineState,
} from '../../../../../../core/e-commerce/_actions/order.action'
import {ProductsExcelMonthlyComponent} from "../products-excel-monthly/products-excel-monthly.component";
import {Actions, ofType} from "@ngrx/effects";
import {OrderActions} from "../../../../../../core/e-commerce";
import {OrdersDatasource} from "../../../../../../core/e-commerce/_data-sources/orders.datasource";
import {OrderExcelProductVenduComponent} from "../order-excel-product-vendu/order-excel-product-vendu.component";
import {OrderModel} from "../../../../../../core/e-commerce/_models/order.model";

export interface wrongOrders {
    orderIds: number;
    hasError: boolean;
}

@Component({
    selector: 'kt-orders-list2',
    templateUrl: './orders-list.component.html',
    styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {

    viewLoading$ = null;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    order: OrderModel2;
    wrongOrders: wrongOrders [];
    isclientOrdresPage: boolean = false;
    private clientId;
    public OrdersTitle: String;
    defaultWidth = 200
    datedefaultWidth = 140
    keyword = 'name';
    matTableMaxHeight;
    regions: RegionModel[] = [];
    columns = [
        {
            "columnDef": 'start',
            width: this.defaultWidth,
            header: '',
            cell: (element: OrderModel2) => '',
            selected: true
        },
        {
            columnDef: 'orderType',
            width: this.defaultWidth,
            type: '',
            header: 'Type',
            cell: (element: OrderModel2) => `${element.orderType ? element.orderType == "GUEST" ? "Achat-Direct" : element.orderType : ''}`,
            selected: false
        },
        {
            columnDef: 'createdAt',
            width: this.datedefaultWidth,
            header: 'Date d\'entrée',
            type: 'Date',
            cell: (element: OrderModel2) => `${element.createdAt}`,
            selected: false
        },
        {
            columnDef: 'id',
            width: 80,
            header: 'ID',
            type: 'number',
            cell: (element: OrderModel2) => `${element.id}`,
            selected: true
        },
        {
            columnDef: 'delivery.deliveryType',
            width: 140,
            type: '',
            header: 'Type de livraison',
            cell: (element: OrderModel2) => `${element.delivery.deliveryType}`,
            selected: true
        },
        {
            columnDef: 'source',
            width: this.defaultWidth,
            type: '',
            header: 'Source',
            cell: (element: OrderModel2) => `${element.source}`,
            selected: false
        },
        {
            columnDef: 'delivery.deliveryAddress.wilaya',
            width: this.defaultWidth,
            type: '',
            header: 'Wilaya',
            cell: (element: OrderModel2) => `${element.delivery.deliveryAddress.wilaya}`,
            selected: false
        },
        {
            columnDef: 'lastcall',
            width: this.defaultWidth,
            type: '',
            header: 'Dernier Appel',
            cell: (element: OrderModel2) => `${element.calls[element.calls.length - 1]}`,
            selected: false
        },
        {
            columnDef: 'delivery.deliveryAddress.firstname',
            width: this.defaultWidth,
            type: '',
            header: 'Client',
            cell: (element: OrderModel2) => `${element.delivery.deliveryAddress.firstname} `,
            selected: true
        },
        {
            columnDef: 'updater',
            width: this.defaultWidth,
            header: 'Updater',
            type: '',
            cell: (element: OrderModel2) => `${element.updater}`,
            selected: true
        },
        {
            columnDef: 'tracking',
            width: this.defaultWidth,
            header: 'Tracking',
            type: '',
            cell: (element: OrderModel2) => `${element.tracking}`,
            selected: true
        },
        {
            columnDef: 'paymentState',
            width: this.defaultWidth,
            header: 'status de paiement',
            type: '',
            cell: (element: OrderModel2) => `${element.paymentState}`,
            selected: true
        },
        {
            columnDef: 'paymentMethod',
            width: this.defaultWidth,
            header: 'Type de paiement',
            type: 'case',
            cell: (element: OrderModel2) => `${element.paymentMethod}`,
            selected: true
        },
        {
            columnDef: 'total',
            width: this.defaultWidth,
            type: '',
            header: 'Total',
            cell: (element: OrderModel2) => `${element.totalTTC}`,
            selected: true
        },
        {
            columnDef: 'products',
            width: this.defaultWidth,
            type: 'products',
            header: 'Produits',
            cell: (element: OrderModel2) => `${(element.orderItems[0] != null) ? element.orderItems[0].configurationTO.name : 'ProductName'}`,
            selected: true
        },
        {
            columnDef: 'state',
            width: 150,
            type: 'case',
            header: 'Statut',
            cell: (element: OrderModel2) => `${element.state}`,
            selected: true
        },
        {
            columnDef: 'deliveryDate',
            width: this.datedefaultWidth,
            type: 'Date',
            header: 'Date de livraison',
            cell: (element: any) => `${element.deliveryDate}`,
            selected: false
        },
        {
            columnDef: 'delivery.deliveryAddress.phone',
            width: this.defaultWidth,
            type: '',
            header: 'Téléphone',
            cell: (element: OrderModel2) => `${element.delivery.deliveryAddress.phone}`,
            selected: false
        },
        {
            columnDef: 'benefit',
            width: this.defaultWidth,
            type: '',
            header: 'bénéfice',
            cell: (element: OrderModel2) => `${element.beneficiaryMargin}`,
            selected: false
        },
        {
            columnDef: 'callHistories',
            width: this.defaultWidth,
            type: '',
            header: 'Historiques des appels',
            cell: (element: OrderModel2) => `${
                element.calls[0] ? element.calls[0].callActionType : '-'}`,
            selected: false
        },
        {
            columnDef: 'priceProduct',
            width: this.defaultWidth,
            type: '',
            header: 'Prix de produit',
            cell: (element: OrderModel2) => `${element.orderItems[0].sellingPrice}`,
            selected: true
        },

        {
            columnDef: 'confirmationDate',
            width: this.datedefaultWidth,
            type: 'Date',
            header: 'date de confirmation',
            cell: (element: OrderModel2) => `${element.confirmationDate}`,
            selected: false
        },
        {
            columnDef: 'updatedAt',
            width: this.datedefaultWidth,
            type: 'Date',
            header: 'Date de changements',
            cell: (element: OrderModel2) => `${element.updatedAt}`,
            selected: false
        },
        {
            columnDef: 'delivery.deliveryPrice',
            width: this.defaultWidth,
            type: '',
            header: 'coût de livraison',
            cell: (element: OrderModel2) => `${element.delivery.deliveryPrice}`,
            selected: false
        },
        {
            columnDef: 'action',
            width: this.defaultWidth,
            type: '',
            header: 'archiver commande',
            cell: (element: OrderModel2) => ``,
            selected: true
        },
        // {
        //     columnDef: 'totalDollar',
        //     width: this.defaultWidth,
        //     type: '',
        //     header: 'Total Dollar',
        //     cell: (element: OrderModel2) => `${element.orderItems[0].configurationTO.buyingPriceDollar}`,
        //     selected: false
        // },
        // {
        //     columnDef: 'totalEuro',
        //     width: this.defaultWidth,
        //     type: '',
        //     header: 'Total Euro',
        //     cell: (element: OrderModel2) => `${element.orderItems[0].configurationTO.buyingPriceEuro}`,
        //     selected: false
        // },
    ];

    displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
    dataSource: OrdersDatasource;
    filterByStatus = new FormControl();
    isLoading = false;
    selectedStatus = new FormControl();
    selectedOrderType = new FormControl();
    selectedRegion = new FormControl();
    selectedEpayMethode = new FormControl();
    selectedDelivery= new FormControl();
    orders: OrderModel2 [] = [];
    order_print;
    order_print_multi: OrderModel2 [] = [];
    loadingUpdatingState = false;

    filterByStatusList = [
        {name: 'Toutes', value: ''},
        {name: 'En attente', value: 'NOT_PAID'},
        {name: 'En attente de paiement', value: 'NOT_PAID_NOT_DELIVERY'},
        {name: 'Payé', value: 'PAID_NOT_DELIVERED'},
        {name: 'Paiement échoué', value: 'PAID_FAILED_NOT_DELIVERED'},
        {name: 'Confirmée', value: 'CONFIRMED'},
        {name: 'En préparation', value: 'NOT_PAID_PREPARED'},
        {name: 'En livraison', value: 'NOT_PAID_IN_DELIVERY'},
        {name: 'Livrée', value: 'PAID_DELIVERED'},
        {name: 'Retourné', value: 'NOT_PAID_RETURNED'},
        {name: 'Annulée', value: 'CANCELED'},
    ]


    filterByStatusPayList = [
        {name: 'Toutes', value: ''},
        {name: 'En attente de paiement', value: 'NOT_READY'},
        {name: 'Payé', value: 'PAYED'},
        {name: 'Paiement échoué', value: 'FAILED'},
    ]

    filterEpayMethodesList = [
        {name: 'Toutes', value: ''},
        {name: 'A la livraison', value: 'CASH_EN_DELIVERY'},
        {name: 'CIB/EDAHABIA', value: 'CIB_ELDAHABIA'},
        {name: 'Virement', value: 'VIREMENT'}
    ]

    filterDeliveryModesList = [
        {name: 'Toutes', value: ''},
        {name: 'à domicile', value: 'DOMICILE'},
        {name: "jusqu'a point de relais", value: 'POINT_DE_RELAIT'},
        {name: 'Livraison électronique', value: 'NO_DELIVERY'},
        {name: 'Récupération au niveau du magasin (Sétif)', value: 'RECUPERATION_AU_NIVEAU_DU_MAGASIN'},
        {name: 'Livraison par Taxi', value: 'DELIVERY_BY_TAXI'}
    ]

    filterByOrderType = [
        {name: 'Toutes', value: ''},
        {name: 'Achat-Direct', value: 'GUEST'},
        {name: 'Client', value: 'CLIENT'},
        {name: 'Admin', value: 'ADMIN'}
    ]

    //{name: '', value: 'GEUST_TO_CLIENT'},

    filterValue: any = {};
    showOrderProductsOf: number = null;
    showCallList: number = null;
    shownProducts: any[] = [];
    callList: any[] = [];
    showCallHistory = true;
    checkedOrders = [];
    search: string;
    totalElements: number;
    filterByDateActivated = false;
    showDatePicker = true;
    loadingData = false;
    // range date
    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
    });

    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null = null;
    toDate: NgbDate | null = null;
    wilayas: Wilaya[];
    ordersAreLoaded: boolean;
    isChecked: boolean = false;
    private subscriptions: Subscription[] = [];

    constructor(private calendar: NgbCalendar,
                private dialog: MatDialog,
                private orderService: OrdersService,
                private activatedRoute: ActivatedRoute,
                public formatter: NgbDateParserFormatter,
                private wilayaService: WilayaService,
                private router: Router,
                private layoutUtilsService: LayoutUtilsService,
                private parcelService: ParcelService,
                private regionService: RegionService,
                private store: Store<AppState>,
                private actions$: Actions) {
    }

    ngOnInit(): void {

        //this.getAllregions();
        this.getAllPaged();


        this.getTableMaxHeight();
        this.wilayas = this.wilayaService.getWilayasItems();

        this.paginator._changePageSize(100);

        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.OrdersTitle = this.activatedRoute.snapshot.data['title'];
            this.clientId = res.get("id");
            this.getSavedListConfig();
            if (res.has("id"))
                this.isclientOrdresPage = true;
            if (this.isclientOrdresPage) {
                this.OrdersTitle = this.OrdersTitle + " > " + this.clientId;
                this.getAllPagedByIdClient(this.clientId);
            } else {
                this.getAllPaged();
            }
        });

        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        this.matTableMaxHeight = 287;

        this.dataSource = new OrdersDatasource(this.store);
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(700), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            map(() => {
                this.paginator.pageIndex = 0;
                if (this.isclientOrdresPage) {
                    this.getAllPagedByIdClient(this.clientId);
                    return;
                }
                this.getAllPaged();
            })).subscribe();
        this.subscriptions.push(searchSubscription);


        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {
                this.getAllPaged();
            })
        ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        this.fieldListener();

    }

    getAllPaged() {
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );

        this.store.dispatch(OrdersAllPageRequested({
            page: queryParams,
            orderState: this.selectedStatus.value,
            region: this.selectedRegion.value,
            startingDate: this.fromDate ? this.formatDate(this.fromDate) : null,
            endingDate: this.toDate ? this.formatDate(this.toDate) : null,
            paymentMethod : this.selectedEpayMethode.value,
            orderType : this.selectedOrderType.value,
            deliveryType : this.selectedDelivery.value
        }));
    }

    getAllPagedByIdClient(idClient) {

        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );

        this.store.dispatch(OrdersByClientPageRequested({
            page: queryParams,
            idClient: idClient
        }));

    }

    filterConfiguration(): string {
        return this.searchInput.nativeElement.value;
    }

    getSavedListConfig() {
        let savedConfig: [{ columnDef: string, width: number, header: string, type?: any, cell: any, selected: boolean }] = JSON.parse(localStorage.getItem('saved-config'));
        if (!savedConfig) {
            localStorage.setItem('saved-config', JSON.stringify(this.columns));
            savedConfig = JSON.parse(localStorage.getItem('saved-config'));
        }
        let newList = [];
        savedConfig.forEach(elem => {
            const selectedelem = this.columns.filter(res => res.columnDef == elem.columnDef);
            let newElement = selectedelem[0];
            newElement.selected = elem.selected;
            newElement.width = elem.width
            newList.push(newElement)
        })
        this.columns = newList;
        this.displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
    }

    fieldListener() {
        this.selectedStatus.valueChanges.subscribe(value => {
            this.getAllPaged();
        });
        this.selectedRegion.valueChanges.subscribe(value => {
            this.getAllPaged();
        });
        this.selectedDelivery.valueChanges.subscribe(value => {
            this.getAllPaged();
        });
        this.selectedOrderType.valueChanges.subscribe(value => {
            this.getAllPaged();
        });
        this.selectedEpayMethode.valueChanges.subscribe(value => {
            this.getAllPaged();
        });
    }

    disableFilterByDate(event) {
        if (event.checked == false) {
            this.loadingData = false;
            this.filterByDateActivated = false;
            this.toDate = null;
            this.fromDate = null;
            this.getAllPaged();
        }
    }

    formatDate(date: NgbDate) {
        var month = '' + (date.month),
            day = '' + date.day,
            year = date.year;
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('/');
    }

    changeOrderStatus(value: string, orderID: number) {
        switch (value) {
            case 'NOT_PAID' :
                this.orderService.changeOrderState(orderID, 'DELAY_PAYMENT').subscribe();
                break;
            case 'NOT_PAID_PREPARED' :
                this.orderService.changeOrderState(orderID, 'PREPARE').subscribe(e => this.updateOrderState());
                break;
            case 'NOT_PAID_IN_DELIVERY' :
                this.orderService.changeOrderState(orderID, 'PREPARE_DELIVERY').subscribe(e => this.updateOrderState());
                break;
            case 'PAID_DELIVERED' :
                this.orderService.changeOrderState(orderID, 'DELIVER_AND_PAY').subscribe(e => this.updateOrderState());
                break;
            case 'NOT_PAID_RETURNED' :
                this.orderService.changeOrderState(orderID, 'RETURN').subscribe(e => this.updateOrderState());
                break;
            case 'CANCELED' :
                this.orderService.changeOrderState(orderID, 'CANCEL').subscribe(e => this.updateOrderState());
                break;
        }
    }

    updateOrderState() {
        this.orderService.getAll().subscribe(
            orders => {
                this.dataSource.entitySubject.next(orders);
                this.dataSource.paginatorTotalSubject.next(orders.length)
            }
        )
    }

    showSettings() {
        const dialogRef = this.dialog.open(OrdersListDialogComponent, {
            width: '600px',
            data: this.columns,
        });

        dialogRef.afterClosed().subscribe(rep => {
            if (rep) {
                this.columns = rep;
                localStorage.setItem('saved-config', JSON.stringify(this.columns));
                this.displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
            }
        })
    }

    showProductsOf(orderId: number) {
        //TODO retrieve the products name
        if (this.showOrderProductsOf === null) {
            this.showOrderProductsOf = orderId;
            this.shownProducts = [];
            this.dataSource.entitySubject.subscribe(orders => {
                orders.find(o => o.id === orderId).orderItems.map(p => this.shownProducts.push(p));
            });
            this.shownProducts.splice(0, 1);
        } else {
            this.showOrderProductsOf = null;
        }
    }

    showCallHistoris(orderId: number) {
        //TODO retrieve call histories
        if (this.showCallList === null) {
            this.showCallList = orderId;
            this.callList = [];
            this.dataSource.entitySubject.subscribe(orders => {
                orders.find(o => o.id === orderId).calls.map(p => this.callList.push(p));
            });
            //this.dataSource.data.find(o => o.id === orderId).calls.map(p => this.callList.push(p));
            this.callList.splice(-1, 0);
        } else {
            this.showCallList = null;
        }
    }

    selectAll(event: any) {
        if (event.checked) {
            const skip = this.paginator.pageSize * this.paginator.pageIndex;
            this.dataSource.entitySubject.subscribe(orders => {
                this.checkedOrders = orders.filter((u, i) => i >= skip)
                    .filter((u, i) => i < this.paginator.pageSize);
            });
        } else {
            this.checkedOrders = [];
        }
    }

    selectRow(order: OrderModel2) {
        this.order_print = order;
        // this.purchase_order_print = order;
        if (this.checkedOrders.includes(order)) {
            const index = this.checkedOrders.indexOf(order, 0);
            if (index > -1) {
                this.checkedOrders.splice(index, 1);
                this.order_print_multi.splice(index, 1);
            }
        } else {
            this.checkedOrders.push(order);
            this.order_print_multi.push(order);
        }
        let opt = this.order_print_multi.find(op => op.state == 'NOT_PAID')
        if (opt == undefined) {
            this.isChecked = false;
        } else {
            this.isChecked = true;
        }
    }

    print(facture: boolean) {

        if (facture) {
            for (let i = 0; i < this.order_print_multi.length; i++) {
                let originalContents = document.body.innerHTML;
                let printContents = document.getElementById(this.order_print_multi[i].id.toString()).innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            }
        } else {
            for (let i = 0; i < this.order_print_multi.length; i++) {
                let originalContents = document.body.innerHTML;
                let printContents = document.getElementById(this.order_print_multi[i].id.toString() + 'x').innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            }
        }
        this.order_print_multi = [];
        this.checkedOrders = [];
        location.reload();
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
            this.filterByDateActivated = true;
            this.showDatePicker = false;
            this.getAllPaged();
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    onResizeEnd(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            this.sort.disabled = true;
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName('mat-column-' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue
            }
            this.columns.find(column => {
                if (column.columnDef == columnName)
                    column.width = event.rectangle.width;
            })
            localStorage.setItem('saved-config', JSON.stringify(this.columns));
        }
        setTimeout(() => {
            this.sort.disabled = false;
        }, 1000)
    }

    selectEvent(item) {
        const queryParams = new QueryParamsModel(
            item.value,
            'desc',
            'id',
            this.paginator.pageIndex,
            this.paginator.pageSize
        );

        this.store.dispatch(OrdersByWilayaRequested({
            pageNumber: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize,
            sortField: 'id',
            sortOrder: 'desc',
            wilaya: item.value
        }));
    }

    inputCleared() {
        this.getAllPaged();
    }

    onResizeTable(event) {
        localStorage.setItem('order-matTableMaxHeight', event.rectangle.height)
        this.matTableMaxHeight = event.rectangle.height;
    }

    getTableMaxHeight() {
        if (localStorage.getItem('order-matTableMaxHeight'))
            this.matTableMaxHeight = Number(localStorage.getItem('order-matTableMaxHeight'))
    }

    getAllregions() {
        this.regionService.getAll().subscribe(res => {
            this.regions = res
        })
    }

    exportWrongOrders(wrongOrders) {

        this.dialog.open(WrongOrdersBillComponent, {
            data: wrongOrders,
            autoFocus: false
        });
    }

    navigate() {
        if (this.isclientOrdresPage) this.router.navigateByUrl('/ecommerce/addOrder/orderdetail/' + this.clientId)
        else this.router.navigateByUrl('/ecommerce/addOrder/customers');
    }

    exportOrdersExcel() {

        this.orderService.ExportExcelOrders().subscribe((response: any) => {

            if (response.message == 'success' && response.body.data) {
                this.wrongOrders = response.body.error.filter(e => e.hasError == true);
                const byteCharacters = atob(response.body.data);

                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers [i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const nav = (window.navigator as any);
                let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                if (nav && nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(blob);
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `Commandes.xlsx`;
                anchor.href = url;

                anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                setTimeout(function () {
                    window.URL.revokeObjectURL(url);
                    anchor.remove();

                }, 100)

                if (this.wrongOrders.length > 0) {
                    this.exportWrongOrders(this.wrongOrders);
                }
            }

        });
    }

    exportSalesStatus() {
        this.dialog.open(ProductsExcelMonthlyComponent, {
            data: "",
            autoFocus: false,
            width: '500px',
        });
    }

    exportOrdersExcelMonthly() {
        this.dialog.open(OrderExcelMonthlyComponent, {
            data: "",
            autoFocus: false,
            width: '500px',
        });
    }

    exportOrdersProduct() {
        this.dialog.open(OrderExcelProductVenduComponent, {
            data: "",
            autoFocus: false,
            width: '500px',
        });
    }

    exportAllOrders() {
        this.orderService.ExportExcelAllOrders().subscribe((response: any) => {

            if (response.message == 'success' && response.body.data) {
                this.wrongOrders = response.body.error.filter(e => e.hasError == true);
                const byteCharacters = atob(response.body.data);

                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers [i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const nav = (window.navigator as any);
                let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                if (nav && nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(blob);
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `AllCommandes.xlsx`;
                anchor.href = url;

                anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                setTimeout(function () {
                    window.URL.revokeObjectURL(url);
                    anchor.remove();

                }, 100)

                if (this.wrongOrders.length > 0) {
                    this.exportWrongOrders(this.wrongOrders);
                }
            }

        });
    }

    copyTracking(tracking) {
        this.parcelService.copytracking(tracking)
    }

    archiveOrder(_item: OrderModel) {
        const _title = 'Archivage de commande ==> ' + _item.id;
        const _description = 'Voulez-vous vraiment archiver ce commande ?';
        const _waitDesciption = 'Archivage en cours...';
        const btnTitle = 'Archiver';
        const dialogRef = this.layoutUtilsService.archiveElement(
            _title,
            _description,
            _waitDesciption,
            btnTitle
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
            this.orderService.archiveOrder(_item.id).subscribe((data) => {
                this.layoutUtilsService.showActionNotification(
                    'La commande est archivé',

                    MessageType.Update
                );
                this.getAllPaged();
            });
        });
    }
    rowData(id) {
        this.router.navigateByUrl("/ecommerce/orders/" + id)
        /*
                if (this.touchtime == 0) {
                    // set first click
                    this.touchtime = new Date().getTime();
                } else {
                    // compare first click to this click and see if they occurred within double click threshold
                    if (((new Date().getTime()) - this.touchtime) < 800) {
                        // double click occurred
                        this.router.navigateByUrl("/ecommerce/orders/" + id)
                        this.touchtime = 0;
                    } else {
                        // not a double click so set as a new first click
                        this.touchtime = new Date().getTime();
                    }
                }*/

    }

    public updateStateOrderFromYalidine() {

        this.loadingUpdatingState = true;

        this.store.dispatch(updateYalidineState());

        this.actions$.pipe(ofType(OrderActions.updateYalidineStateRequestedSucessfully)).subscribe((data) => {
            if (data) {
                this.layoutUtilsService.showActionNotification('les statuts sont mise à jour avec succés!', MessageType.Create, 10000, true, false);
                this.updateOrderState();
                this.loadingUpdatingState = false;
            }
        });

        this.actions$.pipe(ofType(OrderActions.updateYalidineStateChangeRequestedFailure)).subscribe((data) => {
            if (data) {
                this.layoutUtilsService.showActionNotification('Une erreur est servue!', MessageType.Create, 10000, true, false);
                this.loadingUpdatingState = false;
            }
        });
    }
}
