import {AgencyBankModel} from '../../../../../../../core/ek-e-commerce/ek-models/agency-bank-model';
import {AgencyBankService} from '../../../../../../../core/ek-e-commerce/ek-services/agency-bank.service';
import {FoldersService} from '../../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {OrderSplit} from '../../../../../../../core/ek-e-commerce/ek-models/orderSplit';
import {ocrInfo} from '../../../../../../../core/ek-e-commerce/ek-models/document-ocr-information';
import {DocumentService} from '../../../../../../../core/services/document.service';
import {Component, Input, OnInit} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {CreditModel} from "../../../../../../../core/ek-e-commerce/ek-models/credit.model";
import {OrderSplitService} from "../../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject} from "rxjs";

import {PDFDocument, rgb, StandardFonts} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import {DocumentModel} from '../../../../../../../core/ek-e-commerce/ek-models/document.model';
import {OrderSplitBankTO} from "../../../../../../../core/ek-e-commerce/ek-models/orderSplitBank.model";
import {finalize} from "rxjs/operators";

@Component({
    selector: "kt-folder-step-five",
    templateUrl: "./folder-step-five.component.html",
    styleUrls: ["./folder-step-five.component.scss"],
})
export class FolderStepFiveComponent implements OnInit {

    @Input() information: ocrInfo;
    @Input() order: OrderSplit;

    bankOtherDocuments$ = new BehaviorSubject<DocumentModel[]>(null);

    displayedColumns: string[] = [
        "Mnt_des_remboursements",
        "Capacité_de_crédit",
        "capacité_dendettement",
        "Mnt_échéance",
        "hamich_el_djidia",
        "Mnt_de_financement",
        "Durée_de_crédit",
        "Date_de_début",
        "Date_de_fin",

    ];
    dataSource: MatTableDataSource<CreditModel> = new MatTableDataSource();
    orderId: number;
    cartCapacity = null;
    orderSplitBankTO: OrderSplitBankTO;

    downLoadFiles$ = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.downLoadFiles$.asObservable();


    loadingB$ = new BehaviorSubject<boolean>(false);
    loadingBank$ = this.loadingB$.asObservable();

    private creditSbj$ = new BehaviorSubject<CreditModel>(null);
    public creditObs$ = this.creditSbj$.asObservable();

    currentRole: string = "";
    morabaha: any;
    sitPerso: string;
    sitProf: string;
    activity: string;
    offreFinancement: any;
    folderId: number;
    showDownloadButton: boolean = false;
    monthlyPaymentWithTax: number;
    salePriceCPAWithTax: number;
    numberOfMonths: number;
    agency!: AgencyBankModel;
    profitMargin: number;
    credit: CreditModel;


    constructor(
        private orderService: OrderSplitService,
        private route: ActivatedRoute,
        private documentService: DocumentService,
        private agencyBankService: AgencyBankService,
        private folderService: FoldersService
    ) {
        this.currentRole = JSON.parse(localStorage.getItem("currentUser")).roles;
    }

    ngOnInit(): void {


        this.route.paramMap.subscribe((params) => {
            this.orderId = Number(params.get("idOrder"));
            this.folderId = Number(params.get('idFolder'));
        });

        this.folderService.folderStateSubj$.subscribe(state => {
            this.showDownloadButton = state === 'EK_GUARTENTEES_PENDING_SIGNATURE' ||
                state === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS' ||
                state === 'EK_PHYSICAL_FOLDER_PENDING' ||
                state === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK' ||
                state === 'EK_WAITING_FOR_CLIENT' ||
                state === 'EK_PHYSICAL_FOLDER_PICKEDUP' ||
                state === 'EK_PHYSICAL_FOLDER_IN_DELIVERY' ||
                state === 'EK_PHYSICAL_FOLDER_DELIVERED' ||
                state === 'EK_PHYSICAL_FOLDER_IN_PROGRESS' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_RESERVE' ||
                state === 'EK_BANK_REFUSED' ||
                state === 'BANK_NOTIFICATION' ||
                state === 'EK_BANK_RESERVE' ||
                state === 'EK_FOLDER_REFUSED' ||
                state === 'EK_ADMIN_FOLDER_CANCELED' ||
                state === 'EK_BANK_IN_PROGRESS' ||
                state === 'EK_CLIENT_NO_RESPONSE' ||
                state === 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK' ||
                state === 'EK_FOLDER_PICKUP_FAILED';
        })

        this.agencyBankService.getAcencyByFolderId(this.folderId).subscribe({
            next: (agencyResp) => {
                if (agencyResp.body) this.agency = agencyResp.body
            },
            error: (err) => {
            }
        })

        this.documentService
            .getDocumentsByFolderId(this.folderId)
            .subscribe((res: any) => {
                this.bankOtherDocuments$.next(
                    res.body.filter(
                        (document: DocumentModel) => document.documentsType === 'BANK_AUTHOR'
                    )
                );
            });

        this.documentService.downloadDocuments(this.orderId, "BANK_EMPTY_AUTHOR").subscribe({
            next: (res: any) => {
                let obj = res.body.find((o: any) => {
                    switch (o.documentsTitle) {
                        case 'MOURABAHA_EQUIPMENT_SUMMARY_NOTE': {
                            this.morabaha = o.documentUrlPreSigned;
                            break;
                        }
                        case 'FINANCING_OFFER': {
                            this.offreFinancement = o.documentUrlPreSigned;
                            break;
                        }
                    }
                });
            },
        });

        this.cartCapacity = JSON.parse(localStorage.getItem("cartCapacity"));

        this.orderService.creditSubject.subscribe(cr => {
            if (cr) {
                this.credit = cr;
                this.creditSbj$.next(cr);
                this.detailCredit(cr);
            }
        });

    }

    detailCredit(credit: CreditModel) {
        if (credit) {
            this.numberOfMonths = credit.numberOfMonths;
            this.monthlyPaymentWithTax = credit.monthlyPaymentWithTax;
            this.salePriceCPAWithTax = credit.salePriceCPAWithTax;
            this.dataSource.data.push(credit);
        }
    }


    addSeparation(value: number): string {
        if (value) {
            const stringValue = value.toString();
            return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        } else {
            return "";
        }
    }

    checkSituation() {
        switch (this.information.personnelInformation?.socialClientCategory) {
            case "SINGLE": {
                this.sitPerso = "Célibataire";
                break;
            }
            case "MARRIED": {
                this.sitPerso = "Marié";
                break;
            }
            case "DIVORCE": {
                this.sitPerso = "Divorcé";
                break;
            }
            case "WIDOWER": {
                this.sitPerso = "Veuf";
                break;
            }
        }

        switch (this.information.professionalSituation?.professionalClientCategory) {
            case "EMPLOYEE": {
                this.sitProf = "Employé";
                break;
            }
            case "RETRIED": {
                this.sitProf = "Retraité";
                break;
            }
            case "MILITARY": {
                this.sitProf = "Militaire";
                break;
            }
            case "MILITARY_RETIRED": {
                this.sitProf = "Retraité militaire";
                break;
            }
        }

        switch (this.information.professionalSituation?.activityDomain) {
            case "PRIVATE": {
                this.activity = "Privé";
                break;
            }
            case "PUBLIC": {
                this.activity = "Publique";
                break;
            }
            case "NOT_CONCERNED": {
                this.activity = "Non concerné";
                break;
            }
        }
    }


    async createPdf() {
        this.downLoadFiles$.next(true);

        let benefitPercent = (0.08 * 100).toFixed(2)
        // Loading Arabic Font
        const url =
            "https://wicommerce-storage.s3.eu-west-2.amazonaws.com/NotoSansArabic-Black.ttf";
        const fontBytes = await fetch(url).then((res) => res.arrayBuffer());


        //*********************** Demande de consultation de la centrale des risque entreprises et Menages de la banque d'algerie **********************************

        const morabaha = await fetch(this.morabaha).then(
            (res) => res.arrayBuffer()
        );

        const MOR = await PDFDocument.load(morabaha);
        MOR.registerFontkit(fontkit);
        const arabicFont2 = await MOR.embedFont(fontBytes);
        const helveticaFont2 = await MOR.embedFont(StandardFonts.HelveticaBold);

        const MORpages = MOR.getPages();
        const MORfirstPage = MORpages[0];
        const MORSecondPage = MORpages[1];
        const MORThirdPage = MORpages[2];
        const MORFourthPage = MORpages[3];
        const MORFifthPage = MORpages[4];

        MORfirstPage.drawText(
            `${this.agency?.nameAgency ? this.agency?.nameAgency : ''}`,
            {
                x: 145,
                y: 758,
                size: 13,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.personnelInformation?.firstName
                    ? String(this.information.personnelInformation.firstName)
                    : ''
            }`,
            {
                x: 110,
                y: 680,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.personnelInformation?.lastName
                    ? String(this.information.personnelInformation.lastName)
                    : ''
            }`,
            {
                x: 130,
                y: 655,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.personnelInformation?.birthDay
                    ? String(this.information.personnelInformation.birthDay)
                    : ''
            }`,
            {
                x: 180,
                y: 630,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.personnelInformation?.placeOfBrith
                    ? String(this.information.personnelInformation.placeOfBrith)
                    : ''
            }`,
            {
                x: 180,
                y: 606.5,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        this.checkSituation()

        MORfirstPage.drawText(
            `${
                this.sitPerso
                    ? this.sitPerso
                    : ''
            }`,
            {
                x: 180,
                y: 582,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        // MORfirstPage.drawText(
        //   `${
        //     (this.information.personnelSituation?.dependentChild || this.information.personnelSituation?.dependentOtherPeople)
        //       ? (this.information.personnelSituation?.dependentChild + this.information.personnelSituation?.dependentOtherPeople)
        //       : '0'
        //   }`,
        //   {
        //     x: 220,
        //     y: 555,
        //     size: 11.4,
        //     font: helveticaFont2,
        //     color: rgb(1, 0, 0),
        //   }
        // );

        MORfirstPage.drawText(
            `${
                this.information.personnelSituation?.personnelSituationAddressFr
                    ? this.information.personnelSituation.personnelSituationAddressFr
                    : ''
            }`,
            {
                x: 170,
                y: 532,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.order?.phone
                    ? this.order?.phone
                    : ''
            }`,
            {
                x: 140,
                y: 510,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.professionalSituation?.profession
                    ? this.information.professionalSituation?.profession
                    : ''
            }`,
            {
                x: 170,
                y: 460,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.professionalSituation?.professionalClientCategory == 'RETRIED' ?
                    this.information.professionalSituation?.retraitDate ? this.information.professionalSituation.retraitDate : this.information.professionalSituation.retraitVerifyDate :
                    this.information.professionalSituation?.recruitmentDate
                        ? this.information.professionalSituation?.recruitmentDate
                        : ''
            }`,
            {
                x: 192,
                y: 437.5,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.professionalSituation?.professionalClientCategory == 'RETRIED' ?
                    this.information.professionalSituation?.monthlySalaryretrait ? this.information.professionalSituation.monthlySalaryretrait + ' DA' : this.information.professionalSituation.netSalaryRENA + ' DA' :
                    this.information.professionalSituation?.salary ? this.information.professionalSituation.salary + ' DA' : ''}`,
            {
                x: 192,
                y: 412,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.professionalSituation?.employer
                    ? this.information.professionalSituation?.employer
                    : ''
            }`,
            {
                x: 278,
                y: 365,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.information.professionalSituation?.employerAddress
                    ? this.information.professionalSituation?.employerAddress
                    : ''
            }`,
            {
                x: 130,
                y: 316,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        MORfirstPage.drawText(
            `${
                this.activity
                    ? this.activity
                    : ''
            }`,
            {
                x: 130,
                y: 293,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            }
        );

        //******Table */

        if (this.order?.orderItems?.length <= 13) {
            let yTable = 635

            this.order.orderItems?.forEach(orderItem => {
                MORSecondPage.drawText(
                    `${
                        orderItem.quantity
                            ? orderItem.quantity
                            : ''
                    }`,
                    {
                        x: 100,
                        y: yTable,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );

                let nameSplited = this.capitalizeWords(orderItem.configurationTO.name.replace(',', ' ')).split(' ')
                let firstLine: string = '';
                let secondLine: string = '';

                firstLine = String(nameSplited.slice(0, 7)).replace(/,/g, ' ');
                secondLine = String(nameSplited.slice(7)).replace(/,/g, ' ');

                MORSecondPage.drawText(
                    `${
                        firstLine
                            ? firstLine
                            : ''
                    }`,
                    {
                        x: 160,
                        y: yTable + 10,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                MORSecondPage.drawText(
                    `${
                        secondLine
                            ? secondLine
                            : ''
                    }`,
                    {
                        x: 160,
                        y: yTable - 10,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                MORSecondPage.drawText(
                    `${
                        orderItem.sellingPrice
                            ? this.addSeparation(orderItem.sellingPrice) + '.00 DA'
                            : ''
                    }`,
                    {
                        x: 485,
                        y: yTable,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                yTable = yTable - 42.5
            })
        } else {
            let yTable = 635
            let ySecondTable = 745

            let firstItems = this.order.orderItems.slice(0, 13)
            let secondItems = this.order.orderItems.slice(13)

            firstItems?.forEach(orderItem => {
                MORSecondPage.drawText(
                    `${
                        orderItem.quantity
                            ? orderItem.quantity
                            : ''
                    }`,
                    {
                        x: 100,
                        y: yTable,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );

                let nameSplited = this.capitalizeWords(orderItem.configurationTO.name.replace(',', ' ')).split(' ')
                let firstLine: string = '';
                let secondLine: string = '';

                firstLine = String(nameSplited.slice(0, 7)).replace(/,/g, ' ');
                secondLine = String(nameSplited.slice(7)).replace(/,/g, ' ');

                MORSecondPage.drawText(
                    `${
                        firstLine
                            ? firstLine
                            : ''
                    }`,
                    {
                        x: 160,
                        y: yTable + 10,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                MORSecondPage.drawText(
                    `${
                        secondLine
                            ? secondLine
                            : ''
                    }`,
                    {
                        x: 160,
                        y: yTable - 10,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                MORSecondPage.drawText(
                    `${
                        orderItem.sellingPrice
                            ? this.addSeparation(orderItem.sellingPrice) + '.00 DA'
                            : ''
                    }`,
                    {
                        x: 485,
                        y: yTable,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                yTable = yTable - 42.5
            })

            secondItems?.forEach(orderItem => {
                MORThirdPage.drawText(
                    `${
                        orderItem.quantity
                            ? orderItem.quantity
                            : ''
                    }`,
                    {
                        x: 100,
                        y: ySecondTable,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );

                let nameSplited = this.capitalizeWords(orderItem.configurationTO.name.replace(',', ' ')).split(' ')
                let firstLine: string = '';
                let secondLine: string = '';

                firstLine = String(nameSplited.slice(0, 7)).replace(/,/g, ' ');
                secondLine = String(nameSplited.slice(7)).replace(/,/g, ' ');

                MORThirdPage.drawText(
                    `${
                        firstLine
                            ? firstLine
                            : ''
                    }`,
                    {
                        x: 160,
                        y: ySecondTable + 10,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                MORThirdPage.drawText(
                    `${
                        secondLine
                            ? secondLine
                            : ''
                    }`,
                    {
                        x: 160,
                        y: ySecondTable - 10,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                MORThirdPage.drawText(
                    `${
                        orderItem.sellingPrice
                            ? this.addSeparation(orderItem.sellingPrice) + '.00 DA'
                            : ''
                    }`,
                    {
                        x: 485,
                        y: ySecondTable,
                        size: 11.4,
                        font: helveticaFont2,
                        color: rgb(1, 0, 0),
                    }
                );
                ySecondTable = ySecondTable - 42.5
            })
        }


        if (this.order.orderItems?.length > 20) {

            MORFifthPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 210,
                y: 435,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFifthPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 185,
                y: 411,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFifthPage.drawText(`${this.credit?.numberOfMonths ? this.credit?.numberOfMonths : ''} Mois`, {
                x: 210,
                y: 387,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFifthPage.drawText(`${benefitPercent}%`, {
                x: 240,
                y: 363,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFifthPage.drawText(`${this.profitMargin ? this.addSeparation(+this.profitMargin.toFixed(2)) + ' DA' : ''}`, {
                x: 250,
                y: 339,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFifthPage.drawText(`${this.credit?.monthlyPaymentWithTax ? this.addSeparation(this.credit?.monthlyPaymentWithTax) + ' DA' : ''}`, {
                x: 220,
                y: 315,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

        } else if (this.order?.orderItems?.length > 15) {
            MORFourthPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 210,
                y: 189.5,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 185,
                y: 165.5,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${this.credit?.numberOfMonths ? this.credit?.numberOfMonths : ''} Mois`, {
                x: 210,
                y: 139.5,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${benefitPercent}%`, {
                x: 240,
                y: 115.5,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${this.credit?.debtCapacity ? this.addSeparation(this.credit?.debtCapacity) + ' DA' : ''}`, {
                x: 250,
                y: 91.5,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFifthPage.drawText(`${this.credit?.monthlyPaymentWithTax ? this.addSeparation(this.credit?.monthlyPaymentWithTax) + ' DA' : ''}`, {
                x: 220,
                y: 759,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })
        } else if (this.order?.orderItems?.length > 10) {
            MORFourthPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 210,
                y: 534,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 185,
                y: 510,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${this.credit?.numberOfMonths ? this.credit?.numberOfMonths : ''} Mois`, {
                x: 210,
                y: 486,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${benefitPercent}%`, {
                x: 240,
                y: 462,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${this.profitMargin ? this.addSeparation(+this.profitMargin.toFixed(2)) + ' DA' : ''}`, {
                x: 250,
                y: 438,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORFourthPage.drawText(`${this.credit?.monthlyPaymentWithTax ? this.addSeparation(this.credit?.monthlyPaymentWithTax) + ' DA' : ''}`, {
                x: 220,
                y: 414,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })
        } else if (this.order?.orderItems?.length > 5) {
            MORThirdPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 210,
                y: 260,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 185,
                y: 236,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${this.credit?.numberOfMonths ? this.credit?.numberOfMonths : ''} Mois`, {
                x: 210,
                y: 212,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${benefitPercent}%`, {
                x: 240,
                y: 188,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${this.profitMargin ? this.addSeparation(+this.profitMargin.toFixed(2)) + ' DA' : ''}`, {
                x: 250,
                y: 164,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${this.credit?.monthlyPaymentWithTax ? this.addSeparation(this.credit?.monthlyPaymentWithTax) + ' DA' : ''}`, {
                x: 220,
                y: 140,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })
        } else if (this.order?.orderItems?.length < 5) {
            MORThirdPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 210,
                y: 650,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${this.credit?.fundingAmount ? this.addSeparation(this.credit?.fundingAmount) + ' DA' : ''}`, {
                x: 185,
                y: 626,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${this.credit?.numberOfMonths ? this.credit?.numberOfMonths : ''} Mois`, {
                x: 210,
                y: 602,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${benefitPercent}%`, {
                x: 240,
                y: 578,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${this.profitMargin ? this.addSeparation(+this.profitMargin.toFixed(2)) + ' DA' : ''}`, {
                x: 250,
                y: 554,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })

            MORThirdPage.drawText(`${this.credit?.monthlyPaymentWithTax ? this.addSeparation(this.credit?.monthlyPaymentWithTax) + ' DA' : ''}`, {
                x: 220,
                y: 530,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(1, 0, 0),
            })
        }


        const offreFinancement = await fetch(this.offreFinancement).then(
            (res) => res.arrayBuffer()
        );


        const OFIN = await PDFDocument.load(offreFinancement);
        OFIN.registerFontkit(fontkit);
        const arabicFont5 = await OFIN.embedFont(fontBytes);
        const helveticaFont5 = await OFIN.embedFont(StandardFonts.HelveticaBold);

        const OFINpages = OFIN.getPages();
        const OFINfirstPage = OFINpages[0];

        OFINfirstPage.drawText(
            `${this.agency?.nameAgency ? this.agency?.nameAgency : ''}`,
            {
                x: 155,
                y: 738.5,
                size: 13,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${this.agency?.addressAgency ? this.agency?.addressAgency : ''}`,
            {
                x: 100,
                y: 726,
                size: 10,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${this.agency?.phone ? 'Téléphone ' + this.agency?.phone : ''}`,
            {
                x: 100,
                y: 711,
                size: 10,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${this.agency?.fax ? 'Fax ' + this.agency?.fax : ''}`,
            {
                x: 100,
                y: 697.5,
                size: 10,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${this.information.personnelInformation.firstName ? (this.information.personnelInformation.firstName!).toLocaleUpperCase() : ''} ${
                this.information.personnelInformation.lastName ? (this.information.personnelInformation.lastName).toLocaleUpperCase() : ''
            }`,
            {
                x: 230,
                y: 519.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${
                this.information.professionalSituation?.professionalClientCategory == 'RETRIED' ?
                    this.information.professionalSituation?.monthlySalaryretrait ? this.information.professionalSituation.monthlySalaryretrait : this.information.professionalSituation.netSalaryRENA :
                    this.information.professionalSituation?.salary ? this.information.professionalSituation.salary : ''} DA`,
            {
                x: 195,
                y: 503.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${this.credit?.fundingAmount ? this.addSeparation(this.credit.fundingAmount) + ",00 DA" : ''}`,
            {
                x: 245,
                y: 487.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `0,00 DA`,
            {
                x: 375,
                y: 471.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${this.credit?.numberOfMonths ? this.credit.numberOfMonths + ' MOIS' : ''}`,
            {
                x: 230,
                y: 356.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${this.credit?.fundingAmount ? this.addSeparation(this.credit.fundingAmount) + ',00 DA' : ''}`,
            {
                x: 210,
                y: 340.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `0,00 DA`,
            {
                x: 220,
                y: 324.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );
        OFINfirstPage.drawText(
            `${this.profitMargin ? this.addSeparation(parseFloat(this.profitMargin.toFixed(2))) + ' DA' : ''}`,
            {
                x: 220,
                y: 308.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${this.credit?.monthlyPaymentWithTax ? this.addSeparation(this.credit.monthlyPaymentWithTax) + ' DA' : ''}`,
            {
                x: 245,
                y: 292.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `${benefitPercent}%`,
            {
                x: 265,
                y: 276.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        OFINfirstPage.drawText(
            `2380.00 DA`,
            {
                x: 250,
                y: 259.5,
                size: 12,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );


        const pdfBytes1 = await MOR.save();
        this.saveByteArray('Note de synthèse', pdfBytes1);
        const pdfBytes5 = await OFIN.save();
        this.saveByteArray('Offre de financement.pdf', pdfBytes5);

        this.downLoadFiles$.next(false);
    }

    saveByteArray(reportName: any, byte: any) {
        var blob = new Blob([byte], {type: "application/pdf"});
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = reportName;
        link.click();
    }

    capitalizeWords(input: string): string {
        return input.replace(/\b\w+\b/g, match => {
            return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
        });
    }

    updateCalculation() {
        this.orderSplitBankTO = {
            numberOfMonths: this.numberOfMonths,
            monthlyPayment: this.monthlyPaymentWithTax,
            salePriceCPA: this.salePriceCPAWithTax,
        }
        this.profitMargin = this.salePriceCPAWithTax - this.credit.fundingAmount;
        this.loadingB$.next(true);
        this.orderService.updateCalculation(this.orderId, this.orderSplitBankTO)
            .pipe(finalize(() => this.loadingB$.next(false))).subscribe({
            next: (result) => {
                this.agencyBankService.getAcencyByFolderId(this.folderId).subscribe({
                    next: (agencyResp) => {
                        if (agencyResp.body) {
                            this.agency = agencyResp.body
                            this.credit.numberOfMonths = this.orderSplitBankTO.numberOfMonths
                            this.credit.monthlyPaymentWithTax = this.orderSplitBankTO.monthlyPayment;
                            this.credit.salePriceCPAWithTax = this.orderSplitBankTO.salePriceCPA;
                        }
                    },
                    error: (err) => {
                    }
                })
            },
            error: (err) => {
            }
        })

    }

    // Function to validate if a number is between 12 and 36
    validateNumberInRange(number: number): boolean {
        return number >= 12 && number <= 36;
    }
}
