import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "translateState",
})
export class TranslateFolderStatePipe implements PipeTransform {
    transform(State: string): string {
        switch (State) {
            //folder status
            case "EK_FOLDER_CREATED":
                return "En cours de création";
            case "EK_FOLDER_IN_PROGRESS":
                return "En cours de traitement Ekiclik";
            case "EK_FOLDER_RESERVE":
                return "Réservé Ekiclik";
            case "EK_FOLDER_REFUSED":
                return "Dossier refusé Ekiclik";
            case "EK_BANK_IN_PROGRESS":
                return "Pré-notification banque";
            case "EK_GUARTENTEES_PENDING_SIGNATURE":
                return "Garanties en cours de signature";
            case "GUARTENTEES_SIGNED_IN_PROGRESS_BANK":
                return "Garanties signés";
            case "EK_ADMIN_FOLDER_CANCELED":
                return "Dossier annulé (ADMIN)";
            case "EK_CLIENT_FOLDER_CANCELED":
                return "Dossier annulé (CLIENT)";
            case "EK_BANK_RESERVE":
                return "Dossier réserve banque";
            case "BANK_NOTIFICATION":
                return "Notification de la banque (Accord)";
            case "EK_BANK_REFUSED":
                return "Dossier Rejeté Banque";
            case "FINANCIAL_FOLDER":
                return "Dossiers Financés";
            case "EK_CONTROL_BEFORE_GUARANTEES_PRINTING":
                return "Control avant impression des garnaties EkiClik";

            //order status ...
            case "EK_ORDER_CREATED":
                return "Commande créée";
            case "EK_ORDER_PENDING":
                return "Commande en attente";
            case "EK_ORDER_IN_PREPARATION":
                return "Commande en préparation";
            case "EK_ORDER_IN_DELIVERY":
                return "Livraison en cours";
            case "EK_ORDER_DELIVERED":
                return "Client livré";
            case "EK_CLIENT_ORDER_CANCELED":
                return "Commande Annulée (Client)";
            default:
                return State;
        }
    }
}
